import styled from 'styled-components';

const OutbrainStyles = styled.div`
    &.footer {
        margin-top: 48px;
    }

    &.hidden {
        height: 1px;
        width: 1px;
        position: absolute;
    }
`;

export default OutbrainStyles;
