import styled from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const SideNavWrapper = styled.nav<{ theme: Theme }>`
  background-color: ${getColor('surfaceWhite')};
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .menu-list {
    @keyframes slide-in {
      0% {transform: translateX(100%); }
      100% {transform: translateX(0); }
    }
    @keyframes slide-out {
      0% {transform: translateX(0); }
      100% {transform: translateX(-100%); }
    }
    @keyframes slide-out-right {
      0% {transform: translateX(-100%);}
      100% {transform: translateX(0);}
    }

    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
    position: relative;

    &.not-open {
      .submenu {
        position: absolute;
        left: 100%;
        width: 100%;
        display: none;
        @media (${breakpoint.lgMin}) {
          left: 375px;
        }
      }
      .level-0 {
        width: 100%;
        right: 0;
        animation: slide-out-right 0.3s;
        animation-timing-function: ease-in;
      }
    }

    &.open {
      .level-0 {
        width: 100%;
        right: 100vw;
        @media (${breakpoint.lgMin}) {
          right: 375px;
        }
        animation: slide-out 0.3s;
        animation-timing-function: ease-in;
        display: none;
      }
      .submenu {
        position: absolute;
        left: 100%;
        width: 100%;
      }
      .active  + .submenu  {
        .level-0.active {
          display: flex;
          justify-content: flex-start;
          right: 0;
        }
        .level-1 {
          padding-left: 27px;

          .menu-link {
            font-size: 18px;
            font-weight: 600;
          }
        }
        display: flex;
        flex-direction: column;
        gap: 24px;
        left: 0;
        animation: slide-in 0.3s;
        animation-timing-function: ease-in;
      }
      .submenu-head {
        justify-content: flex-start;
        a:not(:only-child) {
          color: ${getColor('textPrimary')};
          position: relative;
          margin-left: 10px;
          flex: none;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            right: 0px;
            bottom: -5px;
            height: 2px;
            background: ${getColor('borderAccentSecondary')};
          }
        }
        .arrow {
          border-color: ${getColor('iconPrimary')};

          :hover {
            border-color: ${getColor('textSecondary')};
          }
        }
      }
    }
  }
`;

export const MenuLabel = styled.span<{ theme: Theme }>`
  font-family: ${font.graphikCond};
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: ${getColor('textSecondary')};
  margin-bottom: 8px;
`;

export const Wrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 24px;

  button {
    font-family: ${font.graphikCond};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    & > label {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const SignInWrapper = styled.div<{ theme: Theme }>`

  span {
    font-family: ${font.graphikCond};
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${getColor('neutralBlack')};
  }

  & .sign-in-button {
    width: 100%;
    color: ${getColor('neutralBlack')};
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: ${font.graphikCond};
  }

  @media (${breakpoint.mdMin}) {
    &.crypto {
      margin: 0 0 24px;
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${breakpoint.mdMin}) {
    gap: 0 48px;
  }

  & > div {
    flex: 1 1 0px;
  }
`;
