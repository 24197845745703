import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getIcon } from 'styles/icons';
import { Theme, getColor } from 'styles/theme';

export const DrawerToggle = styled.input`
  &.drawer-toggle {
    display: none;

  &:checked {
    & ~ .drawer-menu {
      visibility: visible;
      left: 0;
      overflow: hidden;
    }

    & ~ .overlay {
      visibility: visible;
      opacity: 0.5;
    }
  }
}
`;

export const DisclaimerWrapper = styled.div`
  position: sticky;
  top: 79px;
  z-index: 9;
  @media (${breakpoint.mdMin}) {
    top: 55px;
  } 
`;

export const DisclaimerArticleWrapper = styled.div`
  position: sticky;
  /* !! use 0px instead of 0, otherwise top will be considered auto */
  top: calc(var(--cc-sbillboard-min-height, var(--ld-height, 0px)) + 79px);
  z-index: 9999;

  @media (${breakpoint.mdMin}) {
    top: calc(var(--cc-sbillboard-min-height, var(--ld-height, 0px)) + 55px);
  }
`;

export const DrawerToggleButton = styled.label<{ theme: Theme }>`
  &.hamburger-toggle {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 16px;
    position: relative;

    @media (${breakpoint.lgMin}) {
	  margin-right: 24px;	
	} 
    
    svg {
      transition: all .2s ease-in-out;
      width: 30px;
      height: 30px;
    }

    &:hover {
      svg{
        fill: ${getColor('textSecondary')};
        transition: all .2s ease-in-out;
      }
    }

    &:focus, &:active {
      svg {
        fill: ${getColor('iconSecondary')};
      }
    }
  }

  &.close-menu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
    width: 27px;
    height: 23px;

    @media (${breakpoint.mdMin}) {
      height: 40px;
      width: 33px;
    }
  
    ${getIcon('svg-states')};

    &::before {
      margin: auto;
      ${getIcon('close-icon', { height: 19, width: 18 })};
      background-color: ${getColor('neutralBlack')};
      
      @media (${breakpoint.mdMin}) {
        ${getIcon('close-icon', { height: 22, width: 23 })};
        background-color: ${getColor('neutralBlack')};
      }
    }
      
  }
`;
