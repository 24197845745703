import { calculateWhereToInsertEmbeds, insertElementsIntoTree } from 'utils/miscUtils/childrenUtils';
import { InStreamListConfig } from 'interfaces/ads/Ad';
import { PageType } from 'interfaces/content/articles/Post';
import Outbrain from 'components/Outbrain';
import InStream from './InStream';

const insertAds = (
  elements: JSX.Element,
  frequency: number,
  renderAds: boolean,
  articleUrl: string,
  section: string,
  lastPWithoutAds?: number,
  pageType?: PageType,
) => {
  if (frequency === 0) return elements;

  const insertAdAfter = calculateWhereToInsertEmbeds(elements, frequency, lastPWithoutAds);
  const insertElementsArray = insertAdAfter.map((after, index) => {
    if (process.env.FEATURE_FLAG_INLINE_OUTBRAIN && index === 0 && ['Lifestyle', 'Retail'].includes(section)) {
      return {
        after,
        node: (
          <Outbrain
            placement='inline'
            replacedAdIndex={index}
            section={section}
            permalink={articleUrl}
          />
        ),
      };
    }
    return {
      after,
      node: (
        <InStream
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={`ad-${index}`}
          delay={0}
          renderAds={renderAds}
          pageType={pageType}
        />
      ),
    };
  });
  return insertElementsIntoTree(elements, insertElementsArray);
};

const InStreamList = ({
  articleUrl,
  section,
  frequency,
  children,
  lastPWithoutAds,
  renderAds,
  pageType,
}: InStreamListConfig) => (
  <div>{insertAds(children, frequency, renderAds, articleUrl, section, lastPWithoutAds, pageType)}</div>
);

export default InStreamList;
